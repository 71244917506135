














































































































import Component from "vue-class-component";
import { PageBase } from "@/core/models/shared";
import { EmpresaService } from "@/core/services/geral";
import { Empresa } from "@/core/models/geral";
import { RelatorioService } from "@/core/services/RelatorioService";
import { AlertSimpleErr } from "@/core/services/shared/AlertService";


@Component
export default class RelatorioDepesaReceita extends PageBase {
    service = new RelatorioService
    itens: any[] = [];
    empresaService = new EmpresaService();
    empresa: Empresa = new Empresa();
    overlay: boolean = false;

    filtroReceitaDespesa: any = {
        numeroRo: null,
        embarcacaoId:null,
        fornecedorId: null,
        clienteId: null,
        dataEntradaAtracacao: null,
        dataSaidaAtracacao: null,
        tipoReceitaId: null,
        tipoDespesaId: null,
        nf : null,
        dataInicio: null,
        dataFim: null,
    };

    mounted() {
        this.filtroReceitaDespesa.numeroRo = this.$route.query.numeroRo,
        this.filtroReceitaDespesa.embarcacaoId = this.$route.query.embarcacaoId,
        this.filtroReceitaDespesa.fornecedorId = this.$route.query.fornecedorId,
        this.filtroReceitaDespesa.clienteId = this.$route.query.clienteId,
        this.filtroReceitaDespesa.dataEntradaAtracacao = this.$route.query.dataEntradaAtracacao,
        this.filtroReceitaDespesa.dataSaidaAtracacao = this.$route.query.dataSaidaAtracacao,
        this.filtroReceitaDespesa.tipoReceitaId = this.$route.query.tipoReceitaId,
        this.filtroReceitaDespesa.tipoDespesaId = this.$route.query.tipoDespesaId,
        this.filtroReceitaDespesa.nf = this.$route.query.nf


        this.Carregar();
    }

    Carregar() {
        this.overlay = true;

        this.empresaService.ObterPorId(this.app.empresaId, "Endereco.Municipio.Estado").then(
            res => {
                this.empresa = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        )

        this.service.RelatorioDespesaReceita(this.filtroReceitaDespesa.numeroRo,this.filtroReceitaDespesa.embarcacaoId,this.filtroReceitaDespesa.fornecedorId,this.filtroReceitaDespesa.clienteId,this.filtroReceitaDespesa.dataEntradaAtracacao,this.filtroReceitaDespesa.dataSaidaAtracacao,this.filtroReceitaDespesa.tipoReceitaId,this.filtroReceitaDespesa.tipoDespesaId,this.filtroReceitaDespesa.nf).then(
            res => {
                this.itens = res.data;
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.overlay = false;
        })
    }
}
