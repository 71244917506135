import { http, httpHeader } from "../ApiConfig";
import { Service } from "./shared";

export class RelatorioService extends Service {
    constructor() {
        super('relatorio');
    }

    public RelatorioDespesaReceita(numeroRo: number, embarcacaoId: number, fornecedorId: number, clienteId: number, dataEntradaAtracacao?: string, dataSaidaAtracacao?: string, tipoReceitaId?: number, tipoDespesaId?: number, nf?: string) {
        return http.get(`${this.GetNomeControle()}/DespesaReceita`, {
            params: {
                numeroRo,
                embarcacaoId,
                fornecedorId,
                clienteId,
                dataEntradaAtracacao,
                dataSaidaAtracacao,
                tipoReceitaId,
                tipoDespesaId,
                nf
            }
        });
    }
}
